import React, { useEffect, useState } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Profile/Actions';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import moment from 'moment'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormLabel from '@material-ui/core/FormLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { Link, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ProfileImageUpload from './../../components/Profile/ProfileImageUpload';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SubscribeField } from './../../components/Footer/Footer.style';

import './profile.css';
import CarListing from './../../components/Profile/CarListing';
import MyCar from './../../components/Profile/MyCar';
import MuPagination from './../../components/MuPagination/index';

const EditProfileValidationSchema = Yup.object().shape({
  fullname: Yup.string().required('Please enter full name'),
  province: Yup.string().required('Please select province'),
  city: Yup.string().required('Please select city'),
  gsm: Yup.number()
    .positive('Must be positive numbers')
    .test('len', 'Must be exactly 9 digits', val => val && val.toString().length === 9),
});

const EditBusinessProfileValidationSchema = Yup.object().shape({
  businessname: Yup.string().required('Please enter business name'),
  businessAddress: Yup.string().required('Please enter business address'),
});

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    minHeight: 'calc(100vh - 675px)'
  },
  tabMenu: {
    // backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: '#000',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
  table: {
    minWidth: 700,
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const packages = {
  free: 'Free Trial',
  gold: 'Gold Package',
  promotion: 'Platinum Package',
  platinum: 'Free Trial',
}

const Profile = (props) => {
  const {
    isLoading,
    isAuthenticated,
    carListTotalResults,
    carListCurrentPage,
    carListLimit,
    favouriteListTotalResults,
    favouriteListCurrentPage,
    favouriteListLimit,
    inquiryListTotalResults,
    inquiryListCurrentPage,
    inquiryListLimit,
    userData,
    provinces,
    cities,
    favouriteList,
    carList,
    inquiryList,
    testDriveList,
    testDriveListTotalResults,
    testDriveListCurrentPage,
    getCurrentUserProfileAction,
    testDriveListLimit,
    resetLoadingFlagAction,
    updateCurrentUserProfileAction,
    updateFavouriteStatusAction,
    deleteMyCarAction,
    changeCarStatusAction,
    loadProvincesAction,
    loadCitiesAction,
    updateCarListAction,
    updateFavouriteListAction,
    updateInquiryListAction,
  } = props;
    // console.log("🚀 ~ Profile ~ favouriteList:", favouriteList)
    // console.log("🚀 ~ Profile ~ userData:", userData)
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [profileTab, setProfileTab] = useState(0);
  const [myCarSearchTerm, setMyCarSearchTerm] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeProfileTab = (event, newValue) => {
    setProfileTab(newValue);
  }

  useEffect(() => {
    if (provinces.length === 0) {
      loadProvincesAction();
    }
  }, [provinces, loadProvincesAction]);

  useEffect(() => {
    if (isAuthenticated) {
      const payloadMyCar = {
        page: carListCurrentPage,
        limit: carListLimit,
      }

      const payloadFav = {
        page: favouriteListCurrentPage,
        limit: favouriteListLimit,
      }

      const payloadInquiry = {
        page: inquiryListCurrentPage,
        limit: inquiryListLimit,
      }
      const payloadTestDrive = {
        page: testDriveListCurrentPage,
        limit: testDriveListLimit,
      }

      getCurrentUserProfileAction(payloadMyCar, payloadFav, payloadInquiry,payloadTestDrive);
    }
    return () => {
      resetLoadingFlagAction();
    };
    // eslint-disable-next-line
  }, [resetLoadingFlagAction, isAuthenticated, getCurrentUserProfileAction])

  useEffect(() => {
    if (!isLoading) {
      window.scrollTo(0, 0);
    }
  }, [isLoading])

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('avatarurl', file)
    updateCurrentUserProfileAction(formData)
  }

  const handleEditProfileFormSubmit = (values) => {
    let newValues = { ...values };
    if (values.city && values.province) {
      newValues = {
        ...newValues,
        city: newValues.city.city_name,
        province: newValues.province.state_name,
      }
    }
    const formData = new FormData();
    Object.keys(newValues).forEach(key => {
      formData.append(key, newValues[key])
    })
    updateCurrentUserProfileAction(formData)
  }

  const onClickUnfavourite = (id) => {
    updateFavouriteStatusAction({ car_id: id });
  }

  const handleDeleteCar = (id) => {
    deleteMyCarAction(id);
  }

  const handleChangeCarStatus = (car_id, status) => {
    const formData = new FormData();
    formData.append('car_id', car_id)
    formData.append('status', status)
    changeCarStatusAction(formData);
  }

  const handleChangeProvince = (id) => {
    loadCitiesAction(id);
  }

  const handleUpdatePageNumber = (offset, pageNumber) => {
    const payload = {
      page: pageNumber,
      limit: carListLimit,
      search: ''
    }
    updateCarListAction(payload)
  }

  const handleUpdatePageNumberFavouriteList = (offset, pageNumber) => {
    const payload = {
      page: pageNumber,
      limit: favouriteListLimit,
    }
    updateFavouriteListAction(payload)
  }

  const handleUpdatePageNumberInquiryList = (offset, pageNumber) => {
    const payload = {
      page: pageNumber,
      limit: inquiryListLimit,
    }
    updateInquiryListAction(payload)
  }
  const handleUpdatePageNumberTestDriveList = (offset, pageNumber) => {
    const payload = {
      page: pageNumber,
      limit: testDriveListLimit,
    }
    updateInquiryListAction(payload)
  }

  const handleSearchMyCarSubmit = (e) => {
    e && e.preventDefault();
    const payload = {
      page: 1,
      limit: carListLimit,
      search: myCarSearchTerm
    }
    updateCarListAction(payload);
  }

  return (
    !isAuthenticated ?
      <Redirect to="/login" />
      :
      <section className={`${classes.root} mx-auto pl-2 pr-2`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Profile | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
        </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Profile</Typography>
        </Breadcrumbs>
        <CssBaseline />
        {isLoading ?
          <Container className="my-4 d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 659px)' }} component="main" maxWidth="sm">
            <CircularProgress />
          </Container>
          :
          <div className={classes.paper}>
            <div className="profile-header">
              <ProfileImageUpload className="profile-header__image" bgimage={userData.avatarurl} uploadFile={uploadFile} />
              <div className="d-flex flex-column profile-name-address w-100 profile-header-buttons profile-header__info">
                <h2 className="font-weight-normal m-0">{userData.fullname}</h2>
                <h3 className="w-100 font-weight-normal font-size-18 pt-1 m-0">{userData.email}</h3>
                <h4 className="font-size-16 pt-2 font-weight-normal m-0">{userData.gsm || ''}</h4>
                <h4 className="pt-2 m-0 text-uppercase">
                  <Chip color="primary" label={userData.subscriptions[0].name} />
                </h4>
                <div className="d-flex flex-wrap mt-3 text-left">
                  <div className="user-stats">
                    <h2>{userData.sold_cars_count}</h2>
                    <small>Sold</small>
                  </div>
                  <div className="user-stats">
                    <h2>{userData.listing_cars_count}</h2>
                    <small>Total Listings</small>
                  </div>
                  <div className="user-stats">
                    <h2>{userData.favourite_cars_count}</h2>
                    <small>Favourites</small>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.tabMenu} mt-4`}>
              <AppBar className="bg-primary text-dark" position="static">
                <Tabs variant="scrollable" scrollButtons="auto" classes={{ indicator: classes.indicator }} value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="My Ads" {...a11yProps(0)} />
                  <Tab label="Favourite Ads" {...a11yProps(1)} />
                  <Tab label="Profile" {...a11yProps(2)} />
                  <Tab label="Subs. History" {...a11yProps(3)} />
                  <Tab label="Inquiries" {...a11yProps(4)} />
                  <Tab label="Test Drive" {...a11yProps(6)} />
                </Tabs>
              </AppBar>
              <TabPanel className="mt-4 profile-tab" value={value} index={0}>
                <Container>
                  <div className="d-flex justify-content-end">
                    <Paper component="form" className={classes.rootPaper} onSubmit={handleSearchMyCarSubmit}>
                      <InputBase
                        className={classes.input}
                        placeholder="Search Your Cars"
                        inputProps={{ 'aria-label': 'search your cars' }}
                        value={myCarSearchTerm}
                        onChange={(e) => setMyCarSearchTerm(e.target.value)}
                      />
                      <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearchMyCarSubmit}>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                  {carListTotalResults > 0 ?
                    <React.Fragment>
                      <div className="d-flex align-items-center justify-content-end flex-wrap">
                        {/* <MuPagination
                          limit={carListLimit}
                          offset={(carListCurrentPage - 1) * carListLimit}
                          total={carListTotalResults}
                          onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumber(offset, pageNumber)}
                        /> */}
                      </div>
                      <Grid container spacing={3}>
                        {carList.map(car => {
                          return <MyCar key={car._id} carData={car} handleDeleteCar={handleDeleteCar} handleChangeCarStatus={handleChangeCarStatus} />
                        })}
                      </Grid>
                      <div className="d-flex align-items-center justify-content-end flex-wrap mt-3">
                        <MuPagination
                          limit={carListLimit}
                          offset={(carListCurrentPage - 1) * carListLimit}
                          total={carListTotalResults}
                          onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumber(offset, pageNumber)}
                        />
                      </div>
                    </React.Fragment>
                    :
                    <Paper component="div" className="shadow d-flex justify-content-center align-items-center font-weight-bold my-3" style={{ height: 50 }}>No cars available</Paper>
                  }
                </Container>
              </TabPanel>
              <TabPanel className="mt-4 profile-tab" value={value} index={1}>
                {favouriteListTotalResults > 0 &&
                  <div className="d-flex align-items-center justify-content-end flex-wrap">
                    <MuPagination
                      limit={favouriteListLimit}
                      offset={(favouriteListCurrentPage - 1) * favouriteListLimit}
                      total={favouriteListTotalResults}
                      onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumberFavouriteList(offset, pageNumber)}
                    />
                  </div>
                }
                {favouriteList.map(car => {
                  return <CarListing key={car._id} onClickUnfavourite={onClickUnfavourite} carData={car} isFavouriteList />;
                })}
                {/* {favouriteListTotalResults > 0 &&
                  <div className="d-flex align-items-center justify-content-end flex-wrap">
                    <MuPagination
                      limit={favouriteListLimit}
                      offset={(favouriteListCurrentPage - 1) * favouriteListLimit}
                      total={favouriteListTotalResults}
                      onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumberFavouriteList(offset, pageNumber)}
                    />
                  </div>
                } */}
              </TabPanel>
              <TabPanel className="shadow mt-4 profile-tab" value={value} index={2}>
                <AppBar className="bg-primary text-dark" position="static">
                  <Tabs variant="scrollable" scrollButtons="auto" classes={{ indicator: classes.indicator }} value={profileTab} onChange={handleChangeProfileTab} aria-label="simple tabs example">
                    <Tab label="Info" {...a11yProps(0)} />
                    <Tab label="Edit Profile" {...a11yProps(1)} />
                    <Tab label="Plan Setting" {...a11yProps(2)} />
                    <Tab label="Edit Company Info" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
                <TabPanel className="shadow profile-edit" value={profileTab} index={0}>
                  <h2 className="heading-md">Manage your Name, ID and Email Addresses.</h2>
                  <p className="my-0">Below are the name and email addresses on file for your account.</p>
                  <List className={classes.root}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Seller/Dealer's Name"
                        secondary={userData.fullname}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Email Address"
                        secondary={userData.email}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Phone Number"
                        secondary={userData.gsm || '-'}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Province"
                        secondary={userData.province}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="City"
                        secondary={userData.city}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Account Type"
                        secondary={packages[userData.subscriptions.type]}
                      />
                    </ListItem>
                  </List>
                </TabPanel>
                <TabPanel className="shadow profile-edit" value={profileTab} index={1}>
                  <h2 className="heading-md">Manage Your Security Settings</h2>
                  <p className="my-0">Manage Your Account</p>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      fullname: userData.fullname,
                      province: { state_name: userData.province },
                      city: { city_name: userData.city },
                      gsm: userData.gsm,
                    }}
                    onSubmit={handleEditProfileFormSubmit}
                    validationSchema={EditProfileValidationSchema}
                  >
                    {(renderProps) => {
                      const { values: formValues, touched, errors } = renderProps;
                      return (
                        <Form className={classes.form}>
                          <SubscribeField
                            className="news-letter-input"
                            label="Full name"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            autoFocus
                            newsletter={true}
                            value={formValues.fullname}
                            onChange={e => renderProps.setFieldValue('fullname', e.target.value)}
                            helperText={touched.fullname && errors.fullname}
                            error={touched.fullname && errors.fullname ? true : false}
                          />
                          <Autocomplete
                            options={provinces}
                            getOptionLabel={option => option.state_name}
                            autoComplete
                            includeInputInList
                            renderInput={params =>
                              <SubscribeField
                                {...params}
                                label="Province"
                                className="news-letter-input"
                                margin="normal"
                                fullWidth
                                newsletter={true}
                                helperText={touched.province && errors.province}
                                error={touched.province && errors.province ? true : false}
                              />
                            }
                            value={formValues.province || null}
                            onChange={(event, value) => {
                              if (value) {
                                handleChangeProvince(value._id)
                                renderProps.setFieldValue('city', null)
                              }
                              return renderProps.setFieldValue('province', value ? value : null)
                            }}
                          />
                          <Autocomplete
                            options={cities}
                            getOptionLabel={option => option.city_name}
                            autoComplete
                            includeInputInList
                            renderInput={params =>
                              <SubscribeField
                                {...params}
                                label="City"
                                className="news-letter-input"
                                margin="normal"
                                fullWidth
                                newsletter={true}
                                helperText={touched.city && errors.city}
                                error={touched.city && errors.city ? true : false}
                              />
                            }
                            value={formValues.city || null}
                            onChange={(event, value) => renderProps.setFieldValue('city', value ? value : null)}
                          />
                          <SubscribeField
                            className="news-letter-input"
                            label="Contact Number"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            newsletter={true}
                            value={formValues.gsm}
                            onChange={e => renderProps.setFieldValue('gsm', e.target.value)}
                            helperText={touched.gsm && errors.gsm}
                            error={touched.gsm && errors.gsm ? true : false}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                              type: 'number'
                            }}
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="default"
                            className={classes.submit}
                          >
                            Update My Info
                          </Button>
                        </Form>
                      )
                    }}
                  </Formik>
                </TabPanel>
                <TabPanel className="shadow profile-edit" value={profileTab} index={2}>
                  <h2 className="heading-md">Manage Your Package Settings</h2>
                  <List className={classes.root}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Current Plan"
                        secondary={userData.subscriptions[0].name}
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Expiration Date"
                        secondary={userData.subscriptions[0].expirycdate}
                      />
                    </ListItem>
                  </List>
                  <Button
                    fullWidth
                    variant="contained"
                    color="default"
                    className={classes.submit}
                    component={Link}
                    to="/subscriptions"
                  >
                    Change Plan
                  </Button>
                </TabPanel>
                <TabPanel className="shadow profile-edit" value={profileTab} index={3}>
                  <h2 className="heading-md">Manage Your Company Settings</h2>
                  <p className="my-0">Manage Your Company</p>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      businessname: userData.businessname,
                      businessAddress: userData.businessAddress,
                      logourl: undefined,
                    }}
                    onSubmit={handleEditProfileFormSubmit}
                    validationSchema={EditBusinessProfileValidationSchema}
                  >
                    {(renderProps) => {
                      const { values: formValues, touched, errors } = renderProps;
                      return (
                        <Form className={classes.form}>
                          <div className="mt-3">
                            <FormLabel className="font-size-12 mb-2" component="legend">Business Logo</FormLabel>
                            <ProfileImageUpload className="business-profile" bgimage={userData.logourl} uploadFile={(file) => renderProps.setFieldValue('logourl', file)} />
                          </div>
                          <SubscribeField
                            className="news-letter-input"
                            label="Business name"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            autoFocus
                            newsletter={true}
                            value={formValues.businessname}
                            onChange={e => renderProps.setFieldValue('businessname', e.target.value)}
                            helperText={touched.businessname && errors.businessname}
                            error={touched.businessname && errors.businessname ? true : false}
                          />
                          <SubscribeField
                            className="news-letter-input"
                            label="Business Address"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            newsletter={true}
                            value={formValues.businessAddress}
                            onChange={e => renderProps.setFieldValue('businessAddress', e.target.value)}
                            helperText={touched.businessAddress && errors.businessAddress}
                            error={touched.businessAddress && errors.businessAddress ? true : false}
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="default"
                            className={classes.submit}
                          >
                            Update Business Info
                          </Button>
                        </Form>
                      )
                    }}
                  </Formik>
                </TabPanel>
              </TabPanel>
              <TabPanel className="shadow mt-4 profile-tab" value={value} index={3}>
                <TableContainer component={Paper} className="shadow-none">
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell align="left">Package</StyledTableCell>
                        <StyledTableCell align="left">Total Listing</StyledTableCell>
                        <StyledTableCell align="left">Duration</StyledTableCell>
                        <StyledTableCell align="left">Price</StyledTableCell>
                        <StyledTableCell align="left">Expiry Date</StyledTableCell>
                        <StyledTableCell align="left">Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.histories.map((history, index) => (
                        <StyledTableRow key={`subscriptionHistory-${index}`}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">{history.name}</StyledTableCell>
                          <StyledTableCell align="left">{history.amount}</StyledTableCell>
                          <StyledTableCell align="left">{history.duration}</StyledTableCell>
                          <StyledTableCell align="left">{history.price}</StyledTableCell>
                          <StyledTableCell align="left">{history.expirycdate}</StyledTableCell>
                          <StyledTableCell align="left">{history.status}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel className="mt-4 profile-tab" value={value} index={4}>
                {inquiryListTotalResults > 0 &&
                  <div className="d-flex align-items-center justify-content-end flex-wrap mt-3">
                    <MuPagination
                      limit={inquiryListLimit}
                      offset={(inquiryListCurrentPage - 1) * inquiryListLimit}
                      total={inquiryListTotalResults}
                      onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumberInquiryList(offset, pageNumber)}
                    />
                  </div>
                }
                <TableContainer component={Paper} className="shadow">
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell align="left">Name</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                        <StyledTableCell align="left">Mobile Number</StyledTableCell>
                        <StyledTableCell align="left">Message</StyledTableCell>
                        <StyledTableCell size="medium" align="left">Date/Time</StyledTableCell>
                        <StyledTableCell align="left">Car Detail</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Number(userData.subscriptions[0].expirytimes) * 1000 < new Date().getTime() ?
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row" colSpan={6}>
                            You need to be a paid subscriber to view car inquiries.
                        </StyledTableCell>
                        </StyledTableRow>
                        :
                        inquiryList.map((inquiry, index) => (
                          <StyledTableRow key={`subscriptionHistory-${index}`}>
                            <StyledTableCell component="th" scope="row">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">{inquiry.name}</StyledTableCell>
                            <StyledTableCell align="left">{inquiry.email}</StyledTableCell>
                            <StyledTableCell align="left">{inquiry.gsm}</StyledTableCell>
                            <StyledTableCell align="left" className="inquiry-message-max-lines">{inquiry.message}</StyledTableCell>
                            <StyledTableCell align="left">
                              <span>{inquiry.date}</span>
                              <br />
                              <span>{inquiry.time}</span>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Link to={`/listing/${inquiry.car_id.slug}`}>View Car</Link>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {inquiryListTotalResults > 0 &&
                  <div className="d-flex align-items-center justify-content-end flex-wrap mt-3">
                    <MuPagination
                      limit={inquiryListLimit}
                      offset={(inquiryListCurrentPage - 1) * inquiryListLimit}
                      total={inquiryListTotalResults}
                      onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumberInquiryList(offset, pageNumber)}
                    />
                  </div>
                }
              </TabPanel>
              
              <TabPanel className="mt-4 profile-tab" value={value} index={5}>
              {testDriveListTotalResults > 0 &&
                <div className="d-flex align-items-center justify-content-end flex-wrap mt-3">
                  <MuPagination
                    limit={testDriveListLimit}
                    offset={(testDriveListCurrentPage - 1) * inquiryListLimit}
                    total={testDriveListTotalResults}
                    onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumberTestDriveList(offset, pageNumber)}
                  />
                </div>
              }
              <TableContainer component={Paper} className="shadow">
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>#</StyledTableCell>
                      <StyledTableCell align="left">Email</StyledTableCell>
                      <StyledTableCell align="left">Car Brand</StyledTableCell>
                      <StyledTableCell align="left">Car Model</StyledTableCell>
                      <StyledTableCell align="left">Mobile Number</StyledTableCell>
                      <StyledTableCell size="medium" align="left">Date/Time</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testDriveList.length==0 ?
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row" colSpan={6}>
                          You need to be apply for a for a test drive services.
                      </StyledTableCell>
                      </StyledTableRow>
                      :
                      testDriveList.map((testDriveItem, index) => (
                        <StyledTableRow key={`subscriptionHistory-${index}`}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">{testDriveItem.email}</StyledTableCell>
                          <StyledTableCell align="left">{testDriveItem.car_brand}</StyledTableCell>
                          <StyledTableCell align="left">{testDriveItem.car_model}</StyledTableCell>
                          <StyledTableCell align="left" >{testDriveItem.mobile_number}</StyledTableCell>
                          <StyledTableCell align="left">
                            <span> {moment(testDriveItem?.created_at).format("MMM DD, YYYY")}</span>
                            <br />
                            <span> {moment(testDriveItem?.created_at).format("hh:mm A")}</span>
                          </StyledTableCell>
                          <StyledTableCell align="left" >{testDriveItem.status}</StyledTableCell>

                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {testDriveListTotalResults > 0 &&
                <div className="d-flex align-items-center justify-content-end flex-wrap mt-3">
                  <MuPagination
                    limit={testDriveListLimit}
                    offset={(testDriveListCurrentPage - 1) * inquiryListLimit}
                    total={testDriveListTotalResults}
                    onClickPageNo={(e, offset, pageNumber) => handleUpdatePageNumberInquiryList(offset, pageNumber)}
                  />
                </div>
              }
            </TabPanel>
            </div>
          </div>
        }
      </section>
  )
}

const mapStateToProps = (state) => ({
  ...state.Profile,
  isAuthenticated: state.Login.isAuthenticated
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
