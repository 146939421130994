import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import { Link } from 'react-router-dom';

// packeges imported by nayan
import $ from "jquery";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

import { Formik, Form } from "formik";

import "./CarFilter.scss";
const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  select__control: (styles, { data }) => ({
    ...styles,
    height:57,
  }),
};
const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    // flexGrow: 1,
    padding: theme.spacing(2),
    zIndex: 1100,
    flex: 1,
  },
  paper: {
    // padding: theme.spacing(5),
    // color: theme.palette.text.secondary,
    // backgroundColor: '#F5C82A',
    background: "transparent",
    boxShadow: "none",
  },
  gridPosition: {
    // marginTop: '-50px',
    position: "relative",
    maxWidth: 1200,
    zIndex: 1100,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
    flex: 1,
    alignSelf: "flex-end",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  form: {
    justifyContent: "center",
    backgroundColor: "#F5C82A",
    padding: 40,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  // searchButton: {
  //   margin: theme.spacing(1),
  //   backgroundColor: '#222222',
  //   height: 50,
  //   alignSelf: 'flex-end',
  //   color: '#ffffff',
  //   '&:hover': {
  //     backgroundColor: '#222222cc',
  //     borderColor: '#0062cc',
  //     boxShadow: 'none',
  //   },
  //   '&:active': {
  //     boxShadow: 'none',
  //     backgroundColor: '#222222',
  //     borderColor: '#005cbf',
  //   },
  //   '&:focus': {
  //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  //   },
  // },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 900,
    height: 65,
    // width: 250,
    alignSelf: "flex-end",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
  },
  textInputHeight: {
    backgroundColor: "#fff",
    border: "1px solid black",
    // maxHeight: 150, // Adjust height as needed
    // overflow: 'auto',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  heightScrollBtn: { height: 68, overflowY: "auto" },

  tabMenu: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: "#F5C82A",
    color: "#000",
    borderRadius: 0,
    fontWeight: 900,
    height: 50,
    width: "25%",
    padding: "18px 16px",
    lineHeight: 1.3,
    opacity: 0.7,
    "&:first-of-type": {
      borderTopLeftRadius: 8,
    },
    "&:last-of-type": {
      borderTopRightRadius: 8,
    },
    "&.active": {
      opacity: 1,
    },
    // width: 250,
    alignSelf: "flex-end",
    "&:hover": {
      backgroundColor: "#F5C82A",
      color: "#000",
    },
  },
  listbox: {
    width: 400,
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CarFilter = (props) => {
  const {
    classes,
    classNames,
    vehicleBrandsList,
    vehicleModelsList,
    carTypes,
    handleSelectVehicleBrand,
    handleRemoveSelectedBrand,
  } = props;

  const [maxPrice, setMaxPrice] = useState("0");
  const [activeTab, setActiveTab] = useState(0);

  const bodyType = carTypes.map((x) => ({ label: x.name, value: x.name }));
  const currentYear = new Date().getFullYear();
  const years = [...new Array(15)].map((x, index) =>
    (currentYear + index - 14).toString()
  );
  years.unshift("older than 2010");
  const yearsList = years.map((x) => ({ label: x, value: x }));

  // STATIC SAMPLE DATA

  const [cars, setCars] = useState([]);
  const [myModels, setMyModels] = useState([]);
  const [myCars, setMyCars] = useState([]);
  const [tags, setTags] = useState([]);

  // const [selectedcars, setCars] = useState([]);
  // const [models, setModels] = useState([]);

  const listHandler = (elem) => {
    $(`#model-block-${elem}`).toggleClass("d-none");

    $(`#model-toggle-btn-${elem}`).toggleClass("show");
  };

  const handleTags = (car) => {
    const is_checked = $(`#${car._id}`).prop("checked");

    if (is_checked) {
      setTags((tags) => [...tags, car.maketitle]);
      setMyCars((myCars) => [...myCars, car]);
    } else {
      setTags(tags.filter((data) => data != car.maketitle));
      setMyCars(myCars.filter((mycar) => mycar._id != car._id));

      // unchecking model boxes...
      for (let i = 0; i < car.models.length; i++) {
        $(`#${car.maketitle.replace(" ", "")}-${i}`).prop("checked", false);
      }

      setMyModels([]);
      setTags([]);
      //
      for (let i = 0; i < cars.length; i++) {
        cars[i].models.map((model, index) => {
          const is_checked = $(
            `#${cars[i].maketitle.replace(" ", "")}-${index}`
          ).prop("checked");
          if (is_checked) {
            setMyModels((myModels) => [...myModels, model]);
            setTags((tags) => [
              ...tags,
              cars[i].maketitle + " " + model.modeltitle,
            ]);
          }
        });

        const car_checked = $(`#${cars[i]._id}`).prop("checked");
        if (car_checked) {
          setTags((tags) => [...tags, cars[i].maketitle]);
        }
      }
    }
  };

  const handlerSelectCarModel = (car, model, index) => {
    const is_checked = $(`#${car.maketitle.replace(" ", "")}-${index}`).prop(
      "checked"
    );
    if (is_checked) {
      setTags((tags) => [...tags, car.maketitle + " " + model.modeltitle]);
      setMyModels((myModels) => [...myModels, model]);

      const check_status = $(`#${car._id}`).prop("checked");
      if (!check_status) {
        $(`#${car._id}`).prop("checked", true);
        setTags((tags) => [...tags, car.maketitle]);
        setMyCars((myCars) => [...myCars, car]);
      }
    } else {
      setTags(
        tags.filter((data) => data != car.maketitle + " " + model.modeltitle)
      );

      setMyModels(
        myModels.filter((mymodel) => mymodel.modeltitle != model.modeltitle)
      );
    }
  };

  useEffect(() => {
    let tags_json = JSON.stringify(tags);
    window.localStorage.setItem("selected_tags", tags_json);

    $(".react-tag-input__tag__remove").on("click", () => {
      myFunction();
    });
  }, [tags]);

  const myFunction = () => {
    $(".options-box").addClass("d-none");
    $(".options-wrapper").addClass("d-none");
    setTimeout(() => {
      let tags_json = window.localStorage.getItem("selected_tags");
      let selected_tags = JSON.parse(tags_json);

      setMyModels([]);
      setMyCars([]);

      // UNCHECKING ALL VALUES...
      for (let i = 0; i < cars.length; i++) {
        // cars loop...
        $(`#${cars[i]._id}`).prop("checked", false);

        // car's model loop
        for (let j = 0; j < cars[i].models.length; j++) {
          $(`#${cars[i].maketitle.replace(" ", "")}-${j}`).prop(
            "checked",
            false
          );
        }
      }

      // selecting values that are in the tags ....

      for (let i = 0; i < cars.length; i++) {
        // code to handle seleted cars...
        for (let x = 0; x < selected_tags.length; x++) {
          if (cars[i].maketitle === selected_tags[x]) {
            setMyCars((myCars) => [...myCars, cars[i]]);
            $(`#${cars[i]._id}`).prop("checked", true);
          }
        }

        for (let j = 0; j < cars[i].models.length; j++) {
          // code to handle selected car models...
          for (let x = 0; x < selected_tags.length; x++) {
            const elem_model_id = cars[i].maketitle.replace(" ", "") + "-" + j;
            const model_str =
              cars[i].maketitle + " " + cars[i].models[j].modeltitle;

            if (model_str === selected_tags[x]) {
              setMyModels((myModels) => [...myModels, cars[i].models[j]]);
              $(`#${elem_model_id}`).prop("checked", true);
            }
          }
        }
      }
    }, [1000]);
  };

  useEffect(() => {
    $("#cars-and-models").on("click", () => {
      $(".options-box").toggleClass("d-none");
      $(".options-wrapper").toggleClass("d-none");
    });

    $("#root").mouseup(function (e) {
      var container = $(".car_listing_main");

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".options-box").addClass("d-none");
        $(".options-wrapper").addClass("d-none");
        //container.hide();
      }
    });
  }, []);

  useEffect(() => {
    setCars(vehicleBrandsList);
  }, [vehicleBrandsList]);

  useEffect(() => {
    window.localStorage.setItem("my_cars", JSON.stringify(myCars));
    // renderProps.setFieldValue("vehicleBrand",myCars)
  }, [myCars]);

  useEffect(() => {
    window.localStorage.setItem("my_models", JSON.stringify(myModels));
  }, [myModels]);

  const getSearchResults = (input_name) => {
    let value = input_name.toLowerCase();
    $(".options-box").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) == 0);
    });
  };

  useEffect(() => {
    $(".react-tag-input__input").on("keyup", () => {
      let value = $(".react-tag-input__input").val();
      if (value.length > 0) {
        $(".options-box").removeClass("d-none");
        $(".options-wrapper").removeClass("d-none");
      } else {
        $(".options-box").addClass("d-none");
        $(".options-wrapper").addClass("d-none");
      }
      getSearchResults(value);
    });
  }, []);

  return (
    <section className={`${classes.root} ${classNames}`} id="section-wrapper">
      <Grid className={classes.gridPosition} container justify="center">
        <Grid item xs={12}>
          <Paper className={`${classes.paper} car-filter__main`}>
            <div className="w-100">
              <Button
                className={`${classes.tabMenu} ${
                  activeTab === 0 ? "active" : ""
                } tab-button`}
                onClick={() => {
                  if (activeTab !== 0) {
                    setActiveTab(0);
                  }
                }}
              >
                Listing
              </Button>
              <Button
                className={`${classes.tabMenu} ${
                  activeTab === 1 ? "active" : ""
                } tab-button`}
                onClick={() => {
                  if (activeTab !== 1) {
                    setActiveTab(1);
                  }
                }}
              >
                Tracking
              </Button>
              <Button
                className={`${classes.tabMenu} ${
                  activeTab === 2 ? "active" : ""
                } tab-button`}
                onClick={() => {
                  if (activeTab !== 2) {
                    setActiveTab(2);
                  }
                }}
              >
                Roadside Assistance
              </Button>
              <Button
                className={`${classes.tabMenu} ${
                  activeTab === 3 ? "active" : ""
                } tab-button`}
                onClick={() => {
                  if (activeTab !== 3) {
                    setActiveTab(3);
                  }
                }}
              >
                Forum
              </Button>
            </div>
            <div style={{ display: activeTab === 0 ? "block" : "none" }}>
              <Formik
                enableReinitialize
                initialValues={{
                  vehicleType: [],
                  vehicleModel: [],
                  vehicleBrand: [],
                  maxPrice: "",
                  vehicleYear: [],
                }}
                onSubmit={props.handleFormSubmit}
              >
                {(renderProps) => {
                  const { submitForm, values: formValues } = renderProps;

                  return (
                    <Form
                      className={`${classes.form} car-filter__form`}
                      onSubmit={renderProps.handleSubmit}
                    >
                      <div className="list-wrapper car_listing_main">
                        <div id="cars-and-models" className="d-flex ai-center">
                          <ReactTagInput
                            autocomplete="true"
                            placeholder={
                              tags.length == 0 ? "Select Brand & Model" : " "
                            }
                            tags={tags}
                            onChange={(newTags) => {
                              setTags(newTags);
                            }}
                          />

                          <svg
                            className="MuiSvgIcon-root"
                            id="tag-input-icon"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M7 10l5 5 5-5z"></path>
                          </svg>
                        </div>

                        <div
                          id="options_wrapper"
                          className="options-wrapper d-none "
                        >
                          {cars &&
                            cars.map((car) => (
                              <div className="options-box d-none">
                                <div className="d-flex ai-center py-2 jc-sb px-3 cars-list">
                                  <span className="inline-block">
                                    <div className="checkbox">
                                      <label className="mb-0">
                                        {car.maketitle}
                                        <input
                                          type="checkbox"
                                          className="mr-2"
                                          value=""
                                          name={`${car.maketitle}`}
                                          id={car._id}
                                          onClick={() => {
                                            handleTags(car);
                                          }}
                                          onChange={() => {
                                            setTimeout(() => {
                                              renderProps.setFieldValue(
                                                "vehicleBrand",
                                                JSON.parse(
                                                  window.localStorage.getItem(
                                                    "my_cars"
                                                  )
                                                )
                                              );
                                            }, 1000);
                                          }}
                                        />
                                        <span className="checkmark"></span>

                                        <small> [{car.count_models}]</small>
                                      </label>
                                    </div>
                                  </span>
                                  <span
                                    className="inline-block f-14 fun-link hide_show_link"
                                    id={`model-toggle-btn-${car._id}`}
                                    onClick={() => listHandler(car._id)}
                                  >
                                    models
                                  </span>
                                </div>
                                {/* {handleSelectVehicleBrand(car)} */}
                                {/* inner block */}
                                <div
                                  className="models ml-5 d-none sub_dropdown_menu"
                                  id={`model-block-${car._id}`}
                                >
                                  {car.models.map((model, index) => (
                                    <div className="checkbox">
                                      <label className="mb-2">
                                        <input
                                          type="checkbox"
                                          className="mr-2"
                                          id={`${car.maketitle.replace(
                                            " ",
                                            ""
                                          )}-${index}`}
                                          value=""
                                          onClick={() =>
                                            handlerSelectCarModel(
                                              car,
                                              model,
                                              index
                                            )
                                          }
                                          onChange={() => {
                                            setTimeout(() => {
                                              renderProps.setFieldValue(
                                                "vehicleModel",
                                                JSON.parse(
                                                  window.localStorage.getItem(
                                                    "my_models"
                                                  )
                                                )
                                              );
                                            }, 1000);
                                          }}
                                        />
                                        <span className="checkmark"></span>
                                        {model.modeltitle}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="car-filter__form-rows marginTop23">
                        <FormControl
                          className={`car-filter__form-control car-filter__form-inner-div`}
                        >
                          <TextField
                            id="max-price"
                            variant="filled"
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid black",
                              height: 65,
                            }}
                            label="Max Price"
                            type="tel"
                            className="max-price__input"
                            value={maxPrice}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            onFocus={() => {
                              if (maxPrice === "0") {
                                setMaxPrice("");
                              }
                            }}
                            onBlur={() => {
                              if (maxPrice === "") {
                                setMaxPrice("0");
                              }
                            }}
                            onChange={(event) => {
                              setMaxPrice(event.target.value);
                              renderProps.setFieldValue(
                                "maxPrice",
                                event.target.value
                              );
                            }}
                          />
                        </FormControl>

                        <FormControl className={`car-filter__form-control`}>
                          <Select
                            isMulti
                            name="colors"
                            options={yearsList}
                            styles={colourStyles}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Vehicle Year"
                            onChange={(values) => {
                              const labels = values.map(({ label }) => label);
                              renderProps.setFieldValue("vehicleYear", labels);
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="car-filter__form-rows marginTop5">
                        <FormControl className={`car-filter__form-control`}>
                          <Select
                            isMulti
                            name="colors"
                            options={bodyType}
                            styles={colourStyles}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Vehicle Type"
                            onChange={(values) => {
                              const labels = values.map(({ label }) => label);
                              renderProps.setFieldValue("vehicleType", labels);
                            }}
                          />
                        </FormControl>

                        <div
                          className="text-center car-filter__form-inner-div"
                          style={{ flex: 1, padding: 5 }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            className={classes.submit}
                            startIcon={<SearchIcon />}
                            onClick={submitForm}
                          >
                            Search
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <div
              className="justify-content-center flex-column align-items-center bg-primary"
              style={{
                display: activeTab !== 0 ? "flex" : "none",
                height: 200,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/search?q=chat2cars&c=apps"
              >
                <img
                  className="img-responsive"
                  alt="apple-logo"
                  src={`${process.env.REACT_APP_BASE_PATH}/media/footer/google.png`}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="mt-3"
                href="https://itunes.apple.com/us/app/chat2cars/id1391679058?ls=1&mt=8"
              >
                <img
                  className="img-responsive"
                  alt="apple-logo"
                  src={`${process.env.REACT_APP_BASE_PATH}/media/footer/apple.png`}
                />
              </a>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </section>
  );
};

export default withStyles(styles)(CarFilter);
