import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Box,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import "./Insurance.css";
import Api from "../../helpers/ApiHandler";

const api = new Api();
const validationSchema = Yup.object().shape({
  phone: Yup.string().required("Phone number is required"),
  // .matches(/^\d{9}$/, "Please enter valid phone number")
});
const useStyles = makeStyles({
  root: {
    "& label.Mui-focused": {
      color: "#F5C82A",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F5C82A",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#F5C82A",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F5C82A",
      },
    },
  },
});

const FormComponent = () => {
  const [status, setStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await api.post("appointments/insurance-inquiry", {
        data: values,
        skipAuth: true,
      });
      if (response.msg) {
        setStatus(true);
        setStatusMsg("Thank you for contacting us!, We will reach you soon");
        console.log("Form submitted successfully");
        setTimeout(() => {
          setStatus((prev) => !prev);
        }, 3000);
        resetForm();
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      setStatusMsg("Error submitting form");
      setStatus(true);
      setTimeout(() => {
        setStatus((prev) => !prev);
      }, 3000);
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };
  const classes = useStyles();
  return (
    <Container
      sx={{
        width: "100%",
      }}
    >
      <Box
        className="insurance"
        style={{
          padding: "150px 0",
        }}
      >
        <Typography
          className="insuramce-title"
          variant="h2"
          style={{
            marginBottom: 20,
            fontWeight: 700,
            fontFamily: "sans-serif",
          }}
        >
          Get{" "}
          <span style={{ color: "#F5C82A" }}>
            MULTIPLE CAR <br /> INSURANCE
          </span>{" "}
          QUOTES at <br /> DISCOUNTED RATES.
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            marginBottom: 20,
            fontSize: "20px",
            fontWeight: 400,
            fontFamily: "sans-serif",
          }}
        >
          Compare affordable car insurance offers from South Africa’s top <br />{" "}
          providers and find better today.
        </Typography>
        <Formik
          initialValues={{ phone: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form
              className="buttons"
              style={{
                width: "50%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  <Field
                    country={"us"}
                    classes={classes}
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    error={Boolean(errors.phone && touched.phone)}
                    helperText={<ErrorMessage name="phone" />}
                  />
                  {status && <>{statusMsg}</>}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    style={{
                      width: "100%",
                      height: 50,
                      backgroundColor: "#F5C82A",
                      color: "#fff",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default FormComponent;
