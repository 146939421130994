import React, { useEffect, useState, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { UIDecorLine } from '../../components/Footer/Footer.style';

import './AboutUs.css';
import { FooterWrapper } from './../../components/Footer/Footer.style';
import Axios from 'axios';

const BASEURL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  rootFooter: {
    position: 'relative',
    zIndex: 1,
  },
}));

const AboutUs = (props) => {
  const [dealerCount, setDealerCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    getTolatDealerCount();
    getTolatUserCount();
    window.scrollTo(0, 0)
    
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getTolatDealerCount = () =>{
    const url = `${BASEURL}users/get-dealer-list`;
    // const url = "http://192.168.1.60:8000/api/v1/users/get-dealer-list"
    Axios.post(url)
      .then(res => {
        //  console.log(res);
        const dealer = res.data;
        setDealerCount(dealer.totalCount)
      })
  }
  const getTolatUserCount = () =>{
    // const url = `${BASEURL}users/all-active-user-count`;
     const url = "http://192.168.1.60:8000/api/v1/users/all-active-user-count"
    Axios.get(url)
      .then(res => {
        // console.log(res);
        const user = res.data;
        setUserCount(user.users_count)
      })
  }

  return (
    <main style={{ minHeight: 'calc(100vh -  584px)' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - chat2cars.ng</title>
        <meta name="description" content="About Chat2cars.ng - One of the leading South Africa based Company to Sell/Buy Used Cars." />
      </Helmet>
      <div className="mx-1200 my-4 mx-auto" style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
          </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">About Us</Typography>
        </Breadcrumbs>
      </div>
      <div className="bg-white py-5">
        <div className="mx-1200 my-4 mx-auto about-us" style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="about-us__content">
            <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary" component="h2" variant="h4"><span className="text-dark">About</span> Chat2Cars</Typography>
            <UIDecorLine />
            <Typography className="text-gray" component="p" variant="h5">
              Your No. 1 Online Car Marketplace where your car becomes your personality.
            </Typography>
            <Typography className="text-gray mt-3" component="p">
              Chat2cars ensures that purchasing your dream car is cost effective and stress free. We provide a platform where both car dealers/private(sellers) and car buyers find a middle ground to transact easily.
            </Typography>
            <Typography className="text-gray mt-1" component="p">
              Chat2cars is very user friendly, time saving and offers you all car brands at unbeatable prices.
            </Typography>
          </div>
          <div className="about-us__image">
            <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/gtr.png`} className="img-responsive" alt="" />
          </div>
        </div>
        <div className="mx-1200 my-4 mx-auto about-us__features" style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} sm={6} md={3}>
              <div className="about-us__features-icon-description">
                <div className="shadow-lg about-us__features-icon rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/key.svg`} width={42} alt="" />
                </div>
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Ownership</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    Its easy to own your dream car today because for us at chat2cars, car deals just got personal.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="about-us__features-icon-description">
                <div className="shadow-lg about-us__features-icon rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/car-engine.svg`} width={42} alt="" />
                </div>
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Technical Assistance</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    With Chat2Cars mobile app, requesting for technical assistance has never been so easy.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="about-us__features-icon-description">
                <div className="shadow-lg about-us__features-icon rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/security.svg`} width={42} alt="" />
                </div>
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Security</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    We have made it easy for you to secure and track your car using our mobile app.
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className="about-us__features-icon-description">
                <div className="shadow-lg about-us__features-icon rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/car-steering-wheel.svg`} width={42} alt="" />
                </div>
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Revolutionary App</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    We have revolutionised the auto industry with owner to owner chat/call via car registration numbers.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="bg-gray pt-5">
        <div className="mx-1200 mt-4 mx-auto" style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="text-center">
            <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary" component="h2" variant="h4">
              <span className="text-dark">Our Key</span> Features
            </Typography>
            <UIDecorLine />
            <Typography className="text-gray mt-3" component="p">
              Your No. 1 Online Car Marketplace where your car becomes your personality.
            </Typography>
          </div>
          <div className="about-key-features mt-5">
            <div className="about-key-features__column-1">
              <div className="about-key-features__feature">
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Unbeatable Prices</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    Chat2cars is very user friendly, time saving and offers you all car brands at unbeatable prices.
                  </Typography>
                </div>
                <div className="key-feature__icon shadow-lg rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/coins.svg`} width={42} alt="" />
                </div>
              </div>
              <div className="about-key-features__feature">
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Technical Assistance</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    Request for technical assistance when you are stuck at any location.
                  </Typography>
                </div>
                <div className="key-feature__icon shadow-lg rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/settings.svg`} width={42} alt="" />
                </div>
              </div>
              <div className="about-key-features__feature">
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Easy Chat</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    Communicate with other road users via the plate number of their car.
                  </Typography>
                </div>
                <div className="key-feature__icon shadow-lg rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/chat.svg`} width={42} alt="" />
                </div>
              </div>
            </div>
            <div className="about-key-features__column-2">
              <img className="img-responsive" src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/app-image.png`} alt="" />
            </div>
            <div className="about-key-features__column-3">
              <div className="about-key-features__feature">
                <div className="key-feature__icon shadow-lg rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/car-steering-wheel.svg`} width={42} alt="" />
                </div>
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Inventory</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    Keep up to date with your transaction history right within our app.
                  </Typography>
                </div>
              </div>
              <div className="about-key-features__feature">
                <div className="key-feature__icon shadow-lg rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/security.svg`} width={42} alt="" />
                </div>
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">Security</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    Vehicle stolen? report it and we will track it for you.
                  </Typography>
                </div>
              </div>
              <div className="about-key-features__feature">
                <div className="key-feature__icon shadow-lg rounded-circle p-4" style={{ width: 'min-content', height: 'min-content' }}>
                  <img src={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/idea.svg`} width={42} alt="" />
                </div>
                <div>
                  <Typography className="text-gray mt-2" component="p" variant="h6">More Features</Typography>
                  <Typography className="text-gray mt-1" component="p">
                    Download Chat2Cars mobile app and explore more amazing features.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterWrapper bgimage={`${process.env.REACT_APP_BASE_PATH}/media/aboutus/background.jpg`} position="center" isabout>
        <div className={`mx-1200 my-4 mx-auto ${classes.rootFooter}`} style={{ paddingLeft: 20, paddingRight: 20, zIndex: 11 }}>
          <Grid container spacing={5} justify="center">
            <Grid className="about-us__statistics" item xs={12} sm={6} md={3}>
              <Typography className="text-white" component="h3" variant="h4">15000+</Typography>
              <Typography className="text-white mt-2" component="h4" variant="h5">Total <span className="text-primary">Cars</span></Typography>
            </Grid>
            <Grid className="about-us__statistics" item xs={12} sm={6} md={3}>
              <Typography className="text-white" component="h3" variant="h4">{dealerCount}+</Typography>
              <Typography className="text-white mt-2" component="h4" variant="h5">Verified <span className="text-primary">Dealers</span></Typography>
            </Grid>
            <Grid className="about-us__statistics" item xs={12} sm={6} md={3}>
              <Typography className="text-white" component="h3" variant="h4">{userCount}+</Typography>
              <Typography className="text-white mt-2" component="h4" variant="h5">Active <span className="text-primary">Users</span></Typography>
            </Grid>
            <Grid className="about-us__statistics" item xs={12} sm={6} md={3}>
              <Typography className="text-white" component="h3" variant="h4">150+</Typography>
              <Typography className="text-white mt-2" component="h4" variant="h5">Featured <span className="text-primary">Vehicles</span></Typography>
            </Grid>
          </Grid>
        </div>
      </FooterWrapper>
      {/* <div className="bg-white py-5">
        <div className="mx-1200 my-4 mx-auto about-us__car-brands" style={{ paddingLeft: 20, paddingRight: 20 }}>
          {workedBrands.map((brand, index) => {
            return (
              <div key={`sigle-clients-brand-${index}`} className="sigle-clients-brand">
                <img src={brand} alt="" />
              </div>
            )
          })}
        </div> 
      </div> */}
    </main>
  )
}

export default AboutUs
