import styled from 'styled-components';
import Slider from 'react-slick';

export const SliderWrapper = styled(Slider)`
  div& {
    div {
      div {
        outline: none;
      }
    }

    .main_slider__main_div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    color: #000;
  }

  .slick-dots li button:before {
    text-align: center;
    opacity: 0.25;
    color: #000;
  }

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
  }

  .slick-dots {
    bottom: 0;
    text-align: center;
  }

  .slick-next:before, .slick-prev:before {
    font-size: 40px;
  }

  .slick-prev {
    left: 25px !important;
    z-index: 1;
    height: 40px;
    width: 40px;
  }

  .slick-next {
    right: 25px !important;
    z-index: 1;
    height: 40px;
    width: 40px;
  }

  &.more-slider .slick-slide img {
    height: 100px;
    width: 100%;
    object-fit: cover;
  }

  &.single-slider .slick-slide img {
    object-fit: contain;
    width: 100%;
  }
`;
