import React, { useState, useEffect } from "react";
import "./Videosiframe.css";
import { Grid } from "@material-ui/core";

const Videoiframe = () => {
  const [videos, setvideos] = useState([]);

  useEffect(() => {
    fetch(
      "https://youtube.googleapis.com/youtube/v3/activities?part=snippet%2CcontentDetails&channelId=UCxj1mq2ygOHq7PcxMvyzbiw&maxResults=25&key=AIzaSyDFda7Jj3cgP96c8OeOcV6wZfL9RRNmjrU"
    )
      .then((res) => res.json())
      .then((data) => {
        const result = data.items.map((item) => {
          return {
            title: item.snippet.title,
            vid: item.contentDetails.upload.videoId,
          };
        });
        setvideos(result);
      });
  }, []);
  const renderVideos = () => {
    return (
      <>
        <Grid container justify="center" spacing={1} className="main_video_sec">
          {videos.map((vdo, index) => (
            <Grid
              key={index}
              className="MuiGrid-grid-md-4 MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 video_main"
            >
              <div className="video_main_inner">
                <h5 className="video_title">{vdo.title}</h5>
                <iframe
                  width="100%"
                  height="280px"
                  src={`//www.youtube.com/embed/${vdo.vid}?rel=0`}
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                ></iframe>
              </div>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Our Videos</h1>
      {renderVideos()}
    </div>
  );
};

export default Videoiframe;
