import React, { useEffect } from 'react';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/ContactUs/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Link } from 'react-router-dom';

// Document Head Manager
import { Helmet } from "react-helmet";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SubscribeField } from '../../components/Footer/Footer.style';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#F5C82A',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: '100%',
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: '2px solid #000',
      }
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000'
    }
  },
}));

const ContactUsValidationSchema = Yup.object().shape({
  fullname: Yup.string().required('Please enter full name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter an email'),
  mobile_number: Yup.number()
    .positive('Must be positive numbers')
    .test('len', 'Must be exactly 9 digits', val => val && val.toString().length === 9),
  subject: Yup.string().required('Please enter subject'),
  message: Yup.string().required('Please enter message'),
});

const ContactUs = (props) => {
  const {
    isLoading,
    hasError,
    hasSuccess,
    message,
    resetAllContactUsOptionAction,
    setLoadingFlagAction,
    submitContactDataAction,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    return () => {
      resetAllContactUsOptionAction();
    };
  }, [resetAllContactUsOptionAction])

  const handleFormSubmit = values => {
    setLoadingFlagAction();
    submitContactDataAction(values);
  }

  return (
    <Container className="my-4 px-3" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - chat2cars.ng</title>
        <meta name="description" content="Contact Chat2cars.ng today for to Buy/sell Used Car. Feel free to contact us, we are happy to assist you." />
      </Helmet>
      <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
          Home
        </Link>
        <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Contact Us</Typography>
      </Breadcrumbs>
      <div className={`shadow bg-white p-4 ${classes.paper}`}>
        <Typography component="h1" variant="h5">
          E-mail Chat2Cars or Call +234 (81) 478-3898
        </Typography>
        {isLoading ?
          <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
            <CircularProgress />
          </Container>
          :
          hasError ?
            <React.Fragment>
              <Alert className="mt-4" style={{ width: '100%' }} severity="error">
                <AlertTitle>Error</AlertTitle>
                {message}
              </Alert>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  component={Link}
                  to="/contact"
                >
                  Try again
                </Button>
            </React.Fragment>
            :
            hasSuccess ?
              <React.Fragment>
                <Alert className="mt-4" style={{ width: '100%' }} severity="success">
                  <AlertTitle>{message}</AlertTitle>
                </Alert>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  component={Link}
                  to="/"
                >
                  Back to Home Page
                </Button>
              </React.Fragment>
              :
              <Formik
                initialValues={{
                  fullname: '',
                  email: '',
                  mobile_number: '',
                  subject: '',
                  message: '',
                }}
                onSubmit={handleFormSubmit}
                validationSchema={ContactUsValidationSchema}
              >
                {(renderProps) => {
                  const { values: formValues, touched, errors } = renderProps;
                  return (
                    <Form className={classes.form}>
                      <SubscribeField
                        className="news-letter-input"
                        label="Full name"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        autoFocus
                        newsletter={true}
                        value={formValues.fullname}
                        onChange={e => renderProps.setFieldValue('fullname', e.target.value)}
                        helperText={touched.fullname && errors.fullname}
                        error={touched.fullname && errors.fullname ? true : false}
                      />
                      <SubscribeField
                        className="news-letter-input"
                        label="Your Email Address"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="email"
                        newsletter={true}
                        InputProps={{
                          type: 'email',
                          autoComplete: 'off'
                        }}
                        value={formValues.email}
                        onChange={e => renderProps.setFieldValue('email', e.target.value)}
                        helperText={touched.email && errors.email}
                        error={touched.email && errors.email ? true : false}
                      />
                      <SubscribeField
                        className="news-letter-input"
                        label="Contact Number"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        newsletter={true}
                        value={formValues.mobile_number}
                        onChange={e => renderProps.setFieldValue('mobile_number', e.target.value)}
                        helperText={touched.mobile_number && errors.mobile_number}
                        error={touched.mobile_number && errors.mobile_number ? true : false}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                          type: 'number'
                        }}
                      />
                      <SubscribeField
                        className="news-letter-input"
                        label="Subject"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        id="subject"
                        name="subject"
                        autoComplete="subject"
                        newsletter={true}
                        value={formValues.subject}
                        onChange={e => renderProps.setFieldValue('subject', e.target.value)}
                        helperText={touched.subject && errors.subject}
                        error={touched.subject && errors.subject ? true : false}
                      />
                      <SubscribeField
                        label="Message"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        id="description"
                        name="description"
                        multiline
                        rowsMax="5"
                        rows="5"
                        newsletter={true}
                        value={formValues.message}
                        onChange={e => renderProps.setFieldValue('message', e.target.value)}
                        helperText={touched.message && errors.message}
                        error={touched.message && errors.message ? true : false}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="default"
                        className={classes.submit}
                      >
                        Submit
                        </Button>
                    </Form>
                  );
                }}
              </Formik>
        }
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  ...state.ContactUs,
  isAuthenticated: state.Login.isAuthenticated
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
