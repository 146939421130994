import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import EmailIcon from '@material-ui/icons/Email'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'
import InstagramIcon from '@material-ui/icons/Instagram'

// Styled components
import { FooterWrapper } from './Footer.style'
import './footer.css'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const aboutLinks = [
  { key: 'aboutus', title: 'About us', link: '/about' },
  { key: 'faqs', title: 'FAQs', link: '/faqs' },
  { key: 'contact', title: 'Contact', link: '/contact' },
  { key: 'dealers', title: 'Dealers', link: '/dealers' },
  { key: 'subscriptions', title: 'Subscriptions', link: '/subscriptions' },
  { key: 'privacy', title: 'Privacy Policy', link: '/privacy' },
  { key: 'terms', title: 'Terms & Conditions', link: '/terms' },
]

const socialLinks = [
  {
    key: 'facebook',
    title: 'Facebook',
    link: 'https://www.facebook.com/Chat2cars',
    icon: FacebookIcon,
  },
  {
    key: 'twitter',
    title: 'Twitter',
    link: 'https://twitter.com/Chat2cars',
    icon: TwitterIcon,
  },
  {
    key: 'youtube',
    title: 'YouTube',
    link: 'https://www.youtube.com/channel/UCxj1mq2ygOHq7PcxMvyzbiw',
    icon: YouTubeIcon,
  },
  {
    key: 'instagram',
    title: 'Instagram',
    link: 'https://www.instagram.com/Chat2cars',
    icon: InstagramIcon,
  },
]

const Footer = () => {
  const classes = useStyles()

  return (
    <FooterWrapper>
      <div className={classes.root}>
        <Grid
          container
          spacing={5}
          justify={'center'}
          style={{ maxWidth: 1200, margin: '-20px 0' }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <div className="footer-section footer-section_info">
              <div className="footer__title" style={{ color: '#fff' }}>
                Chat2Cars
              </div>
              <div className="footer__slogan" style={{ color: '#fff' }}>
                Cars Personified
              </div>
              <div className="footer-info" style={{ color: '#fff' }}>
                Your No. 1 Online Car Marketplace where your car becomes your
                personality.
              </div>
              <div className="footer-contacts">
                {/* https://www.google.com/maps/place/20+Kruger+St,+City+and+Suburban,+Johannesburg,+2094,+South+Africa/@-26.2045752,28.0572413,17z */}
                <a
                  className="footer-contacts__item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/93+Grayston+Dr,+Morningside,+Sandton,+2057,+South+Africa/@-26.0980052,28.0534313,17z"
                >
                  <LocationOnIcon className="text-primary" />{' '}
                  <div
                    className="footer-contacts__item_content"
                    style={{ color: '#fff' }}
                  >
                    93 Grayston Drive Sandton
                  </div>
                </a>
                <div className="footer-contacts__item">
                  <EmailIcon className="text-primary" />{' '}
                  <div className="footer-contacts__item_content">
                    <a
                      href="mailto:info@chat2cars.co.za"
                      style={{ color: '#fff', textDecoration: 'none' }}
                    >
                      info@chat2cars.co.za
                    </a>
                  </div>
                </div>
                <a
                  className="footer-contacts__phone mt-4"
                  style={{ color: '#fff', textDecoration: 'none' }}
                  href="tel:+27814783898"
                >
                  +27 (81) 478-3898
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={5} justify={'center'}>
              <Grid item xs={6}>
                <h3 className="footer-section__title mb-3 text-primary">
                  Quick Links
                </h3>
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  className="p-0"
                >
                  {aboutLinks.map((aboutLink) => {
                    return (
                      <ListItem
                        key={aboutLink.key}
                        className="p-0"
                        button
                        component={Link}
                        to={aboutLink.link}
                      >
                        <ListItemText
                          style={{ color: '#fff' }}
                          primary={aboutLink.title}
                        />
                      </ListItem>
                    )
                  })}
                </List>
              </Grid>
              <Grid item xs={6}>
                <h3 className="footer-section__title mb-3 text-primary">
                  Follow Us
                </h3>
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  className="p-0"
                >
                  {socialLinks.map((socialLink) => {
                    return (
                      <ListItem
                        key={socialLink.key}
                        className="p-0"
                        button
                        component="a"
                        href={socialLink.link}
                        target="_blank"
                      >
                        <ListItemIcon className="min-width-0">
                          <socialLink.icon className="text-primary" />
                        </ListItemIcon>
                        <ListItemText
                          className="pl-2"
                          style={{ color: '#fff' }}
                          primary={socialLink.title}
                        />
                      </ListItem>
                    )
                  })}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <h3 className="footer-section__title mb-3 text-primary">
              Download Our App
            </h3>
            <div className="d-flex flex-column">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/search?q=chat2cars&c=apps"
              >
                <img
                  className="img-responsive"
                  alt="apple-logo"
                  src={`${process.env.REACT_APP_BASE_PATH}/media/footer/google.png`}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="mt-3"
                href="https://itunes.apple.com/us/app/chat2cars/id1391679058?ls=1&mt=8"
              >
                <img
                  className="img-responsive"
                  alt="apple-logo"
                  src={`${process.env.REACT_APP_BASE_PATH}/media/footer/apple.png`}
                />
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
    </FooterWrapper>
  )
}

export default Footer
