import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Helmet } from "react-helmet";
// import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';

import { sidebar } from './../PostAd';

import './faq.css'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  accordion: {
    background: 'transparent',
    boxShadow: 'none',
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    flexShrink: 0,
    fontWeight: 'bold',
  },
}));

const faqsList = [
  {
    title: 'How Can I Advertise My Car on Chat2Cars?',
    content: `<ol><li>As a private Seller. click on the tab that says register, Once you have filled up the registration form, you can then click on sell a car and fill the sell a car information with the pictures of the car. once that is done, the car will appear on our home page.</li><li>As a Dealer. follow step 1. Above, after which click on the subscription button and select the package that best suites you. Once that is done, you will receive our banking details on your email. once payment is made, you can then send us the proof of payment to commence making you a dealer. enquiries/ proofs can be sent to info@chat2cars.ng or contact our admin on +234 (81) 478-3898</li></ol>`,
  },
  {
    title: 'How do I Contact Chat2cars?',
    content: `<p>We are very keen to hear all feedback, so please contact us on info@chat2cars.ng or +234 (81) 478-3898</p><p>We appreciate all feedback and all comments will be considered so that we can continuously improve our site and app for all users. In the unlikely event that you discover a 'bug', glitch or something wrong with the site, please use the mailus form provided - we really do read every piece of feedback and we appreciate your help.</p>`,
  },
  {
    title: 'How do I use Chat2cars App or Website.?',
    content: `<p>chat2cars is available on&nbsp;<a href="https://play.google.com/store/apps/details?id=com.chat2cars.app&amp;hl=en_ZA" target="_blank" rel="noopener">Google Play Store(Android)</a>&nbsp;and&nbsp;<a href="https://itunes.apple.com/us/app/chat2cars/id1391679058?ls=1&amp;mt=8" target="_blank" rel="noopener">App Store (iOS)</a>&nbsp;and also on the world wide web (www.chat2cars.ng).</p><ol><li>To use chat2cars App. You download on Google Play Store(Android) and App Store (iOS), you fill up the requested informations, which entails the your name, phone number, make and model of car. a verification message will be sent to the provided phone number after which ,you can contact anyone registered on chat2cars through their car plate number or user name.<br />From the app, you can do the following.</li><ol><li>Chat/call to anyone through the car/reg number</li><li>Request for Technical Assistance ( Mechanic, Electrician or Tow Van)</li><li>You can view your chats with any car user on the history, which has been encrypted and safe.</li><li>Report a stolen car, with the help of the admin, you can report a suspicious car, which immediately is then reported to the authority and the plate no is displayed on all users of the app. to enhance the attention given to the car.</li><li>Filter and quick search for the best affordable car and compare prices between multiple dealerships</li><li>On my profile, you can change, your car plate number, if it happens that you are changing cars, you can put up a profile picture</li></ol><li>To use chat2cars website: the website has been designed to be fast ,accurate, user friendly competitive. The website uses a drop down, such that as you are typing the car you have in mind, it will be showing you the possible cars that are related to what you are looking for.</li></ol>`,
  },
  {
    title: 'How much is it to Register?',
    content: `<p>It's free. You don't need to register to use the main search area, however things like asking our experts a question, placing an advert or adding vehicles to your Shortlist mean we need to know who you are, so registering is needed.</p><p>We don't share your details with any other parties. Although to register as a dealer, in the interest of our clients, a copy of the dealership's proxy id and the proof of payment is required to complete the registration process.</p>`,
  },
  {
    title: 'What internet browser works best?',
    content: `<p>The Chat2cars site has been tested in all major browsers, for optimal performance however please ensure you use browser versions above Internet Explorer 9, Firefox 35.0, Google Chrome 38.0 and Safari 8.0.</p><p>Upgrading your browser is also an excellent way to maximise your online security and keep safe online.</p>`,
  },
  {
    title: 'Why are there 2 ways of searching?',
    content: `<p><strong>1. Home Page Search :</strong>&nbsp;We found that about half the people who use our site have decided on the exact car they want to buy, and their budget, so to make things easy we have kept our single make/model and max price search, so you can go straight through to the right car the first time.</p><p><strong>2. Listings Page Search :</strong>When shopping for a car, many buyers who use our site have several cars on their shortlist. So, to help them along we have introduced keyword searching, which allows buyers to be more general in their first search and to fine tune the results. Click on the listing button on the Home Page to use Keyword Search and other search filters like Year and Mileage.</p><p>The clever new search filters on the results page allow buyers to choose multiple makes, models and body types so you can compare similar vehicles side by side, easily. To use them, simply click on the filters and make your selection. Once you are happy with all your search refinements, click apply. You can update your results after each selection or at the end of all your refinements.</p><p><strong>Here are some tips which will help you find your next car.</strong></p><ol><li>We have kept the popular drop down menus, but have now added in the number of cars that match that make, model and price range, making it easier to see how many you have to choose from.</li><li>Keyword on the home page as another way of searching for cars, simply type the car you want (i.e. BMW 1 SERIES) and even if you accidentally spell it wrong the search will correct it for you.</li><li>On the search results we have moved the advanced search to the RIGHT, rather than the top, so you can see more adverts on the page.</li><li>You can choose to use as many or as few search options as you need, and you can just hop between them as you wish, there is no need to 'update results' every time.</li></ol>`,
  },
  {
    title: 'Keywords',
    content: `<p>The keyword has a 'self correct' mechanism, which will help match your keyword if we think it's been misspelled, and we also offer 'did you mean' which matches up similar words if we can't find what you're looking for. These will work automatically as you search, so you can be sure we'll help find your dream car!</p>`,
  },
  {
    title: 'What is a \'Featured Listing\'?',
    content: `<p>A Featured Listing is a product we offer advertisers to find buyers closest to them that are searching for a vehicle they have advertised.</p><p>The Featured Listing will conveniently appear at the top of search for you the buyer, highlighting the vehicle for sale most closely representing your search criteria and is the closest vehicle advertised to your set location making it as easy as possible to find and purchase your next vehicle.</p>`,
  },
  {
    title: 'How do I advertise on your website?',
    content: `<p>Its easy, visit https://chat2cars.ng/ and follow the Sell Your Car link to enter your advert online. There are 5 simple steps which you will follow to place an advert. You'll need to register though to place an advert.</p><p><strong>Here are some tips when placing an advert online</strong></p><ol><li>Give as much detail as possible - buyers&rsquo; online associate transparency and information with trust and your advert will sell much quicker.</li><li>Upload as many pictures as possible - just as above, the more images you upload the more trust you create and give your vehicle a better chance of selling quicker</li><li>Price your vehicle right - do your homework on similar vehicles with similar mileage and condition to give you an indicative price range before you place your advert.</li></ol>`,
  },
  {
    title: 'How do I make changes to an existing advert?',
    content: `<p>Visit https://chat2cars.ng/. Login with your e-mail address and password. Click on my profile and follow the simple steps to amend an existing advert.</p><p>Alternatively, you contact Customer Service on +234 (81) 478-3898 or e-mail info@chat2cars.ng and one of our friendly agents will be more than happy to assist you.</p>`,
  },
  {
    title: 'How do I rebook/reactivate an advert?',
    content: `<p>Your advert will be displayed on https://chat2cars.ng/ until the expiry date. To rebook, expire or amend your advert, <a href="https://chat2cars.ng/subscriptions">https://chat2cars.ng/subscriptions</a>.</p><p>Sign In, click on "Subscriptions" then "Select the package of your choice". You can manage your Live Adverts, Expired Adverts, Payment Pending Adverts.</p>`,
  },
  {
    title: 'How do I pay for my advert?',
    content: `<p>For your convenience you may pay for your order via EFT or a cash deposit (Regret: no cheques accepted).</p><p>Please fax or email proof of payment together with your Company registration to info@chat2cars.ng. You can also contact our sales desk by calling +234 (81) 478-3898 once payment has been made to have your advert activated instantly.</p>`,
  },
];

const FAQs = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <main className="mx-1200 my-4 mx-auto" style={{ minHeight: 'calc(100vh -  608px)', paddingLeft: 20, paddingRight: 20 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQs | Sell Your Car at Best Prices | Advertise with Us | Chat2Cars</title>

        <meta name="title" content="FAQs | Sell Your Car at Best Prices | Advertise with Us | Chat2Cars" />
        <meta name="description" content="Here you will find the frequently asked questions about car advertisement, selling your car, car registration, and our best packages." />
        <meta property="og:title" content="FAQs | Sell Your Car at Best Prices | Advertise with Us | Chat2Cars" />
        <meta property="og:image" content="https://chat2cars.ng/logo.png" />
      
      </Helmet>
      <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
          Home
        </Link>
        <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">FAQs</Typography>
      </Breadcrumbs>
      <div className="faq__main-div my-4">
        <div className="faq__title-block">
          <Typography className="text-dark font-weight-bold py-3 pl-3" component="h2" variant="h5">
            Frequently Asked Questions
          </Typography>
        </div>
        <div className="faq__form-block shadow">
          {faqsList.map((faq, index) => {
            return (
              <ExpansionPanel key={`faq-${index}`} className={classes.accordion} expanded={expanded === index} onChange={handleChange(index)}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>{faq.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography component="div" dangerouslySetInnerHTML={{ __html: faq.content }} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })}
        </div>
        <div className="faq__sidebar shadow">
          {sidebar()}
        </div>
      </div>
    </main>
  )
}

export default FAQs
