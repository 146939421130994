import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Results/Actions';
import SidebarFilter from '../../components/SidebarFilter';
import CarSearchListing from '../../components/CarSearchListing';
import { FloatingButton } from './CarResults.style';
// Document Head Manager
import { Helmet } from "react-helmet";
import "./car-results.css";
import GotoLoginDialog from './../../components/GotoLoginDialog';

const styles = theme => ({
  root: {
    maxWidth: 1280,
    margin: '0 auto'
  }
})

class CarResults extends Component {

  state = {
    filterDrawer: false
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { filterParams: { limit }, getSearchResultsDataAction, getBrandsDataAction, getProvinceDataAction, getCarTypesDataAction } = this.props;
  
    const searchParams = new URLSearchParams(window.location.search);
    const brandsParam = searchParams.get("brands");
    const modelsParam = searchParams.get("models");
    const yearsParam = searchParams.get("year");
    const maxPriceParam = searchParams.get("maxprice");
    const bodyTypesParam = searchParams.get("types");
    const minPriceParam = searchParams.get('minprice');
    const pageParam = searchParams.get('page');
    const fuelParams = searchParams.get('fueltypes');
    const sortyByParams =searchParams.get('sortby');
    const transmissionParams = searchParams.get('transmissiontypes');
    const payload = {
      page: pageParam?pageParam:1,
      limit,
      make: brandsParam? brandsParam : '',
      model: modelsParam?modelsParam:'',
      bodytype: bodyTypesParam?bodyTypesParam:'',
      year: yearsParam?yearsParam: '',
      fueltype: fuelParams?fuelParams: '',
      min_price: minPriceParam?minPriceParam:0,
      max_price: maxPriceParam?maxPriceParam:5000000,
      transmission: transmissionParams?transmissionParams:'',
      sortBy: sortyByParams?sortyByParams: 'most_recent'
    }
    await getBrandsDataAction();
    await getSearchResultsDataAction(payload, undefined);
    await getCarTypesDataAction();
    await getProvinceDataAction();
  }

  componentWillUnmount() {
    this.props.clearAllFilterAndDataAction();
  }

  handleFilterDrawerToggle = () => {
    this.setState((prevState) => ({
      filterDrawer: !prevState.filterDrawer
    }))
  }

  handleBrandChange = (brands) => {
    let { selectedBrands } = this.props;
    const selectLegth = selectedBrands.length;
    const newLength = brands.length;
    if (selectLegth > newLength) {
      const removedBrand = selectedBrands.filter(({ maketitle: id1 }) => !brands.some(({ maketitle: id2 }) => id2 === id1))[0];
      this.props.removeBrandFromSelectedAction(removedBrand, brands);
    } else {
      const newSelectedBrand = brands.filter(({ maketitle: id1 }) => !selectedBrands.some(({ maketitle: id2 }) => id2 === id1))[0];
      
      this.props.updateBrandsFieldAction(brands, newSelectedBrand);
    }
  }

  handleModelChange = (models) => {
    this.props.updateModelsFieldAction(models);
  }

  handleChangeOtherFieldsData = (fieldName, value) => {
    if (fieldName === 'fuelType') {
      this.props.updateFuelTypeFieldAction(value);
    } else if (fieldName === 'transmission') {
      this.props.updateTransmissionFieldAction(value);
    } else if (fieldName === 'price') {
      this.props.updatePriceFieldAction(value);
    } else if (fieldName === 'kmsDriven') {
      this.props.updateKmsDrivenFieldAction(value);
    } else if (fieldName === 'selectedYears') {
      this.props.updateYearsFieldAction(value);
    } else if (fieldName === 'pageNumber') {
      this.props.updatePageNoAction(value);
    } else if (fieldName === 'sortBy') {
      this.props.updateSortByFilterAction(value);
    } else if (fieldName === 'province') {
      this.props.updateProvinceFilterAction(value);
    } else if (fieldName === 'city') {
      this.props.updateCityFilterAction(value);
    } else if (fieldName === 'body') {
      this.props.updateBodyTypeFilterAction(value);
    } else if (fieldName === 'clearAll') {
      this.props.resetAllFilterAction();
    }
  }

  handleLikeUnLikeCar = (id) => {
    if(this.props.isAuthenticated) {
      this.props.handleLikeUnLikeCarAction(id);
    } else {
      this.setState({
        isDialogOpen: true,
      });
    }
  }

  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    })
  }

  handleChangeProvince = (id) => {
    this.props.loadCitiesAction(id);
  }

  render() {
    const { classes, searchResults,isAuthenticated, loading, brandsList, modelList, selectedBrands, selectedModels, body, carTypes, fuelType, transmission, price, kmsDriven, selectedYears, province, city, provinces, cities, filterParams, totalResults, history } = this.props;
    const { filterDrawer, isDialogOpen } = this.state;
    return (
      <div id="carResult_mainn">
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Car Listing | Used Cars for Sale | Car Dealership | Chat2Cars</title>
          <meta name="title" content="Car Listing | Used Cars for Sale | Car Dealership | Chat2Cars" />
          <meta property="og:title" content="Car Listing | Used Cars for Sale | Car Dealership | Chat2Cars" />
          <meta name="description" content="Check out the list of used cars for sale at affordable prices - Chat2Cars provides the best car dealership service, based in South Africa." />
          <meta property="og:image" content="https://chat2cars.ng/logo.png" />
        </Helmet>
        <Breadcrumbs className="p-3 mt-3" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
          </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Listing</Typography>
        </Breadcrumbs>
        <div className="car-results p-1">
          <SidebarFilter
            isFilterDrawerOpen={filterDrawer}
            closeFilterDrawer={this.handleFilterDrawerToggle}
            brandsList={brandsList}
            selectedBrands={selectedBrands}
            selectedModels={selectedModels}
            modelList={modelList}
            handleChangeOtherFieldsData={this.handleChangeOtherFieldsData}
            handleBrandChange={this.handleBrandChange}
            handleModelChange={this.handleModelChange}
            handleChangeProvince={this.handleChangeProvince}
            fuelType={fuelType}
            transmission={transmission}
            price={price}
            kmsDriven={kmsDriven}
            selectedYears={selectedYears}
            province={province}
            city={city}
            provinces={provinces}
            cities={cities}
            body={body}
            carTypes={carTypes}
          />
          <CarSearchListing
            loading={loading}
            handleChangeOtherFieldsData={this.handleChangeOtherFieldsData}
            {...filterParams}
            totalResults={totalResults}
            searchResults={searchResults}
            handleLikeUnLikeCar={this.handleLikeUnLikeCar}
            isAuthenticated={isAuthenticated}
          />
        </div>
        <GotoLoginDialog open={isDialogOpen} handleClose={this.handleCloseDialog} history={history} />
        <FloatingButton className="car-results-filter_button" bgcolor="#263f44" onClick={this.handleFilterDrawerToggle}>
          <SearchIcon />
        </FloatingButton>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.SearchResults,
    isAuthenticated: state.Login.isAuthenticated,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CarResults));